.container {
  height: 100%;
  max-height: 65vh;
  margin: 15px;
  margin-top: 0;
  padding: 5px;
  display: flex;
  background-color: var(--table-container-primary);
  flex-direction: column;
}


@media (max-width: 1050px) {
  .container {
    max-height: max-content;
  }
}

@media (max-width: 700px) {
  .container {
    max-height: max-content;
    width: auto;
  }
}