.leftside {
    margin: 2% 1%;
    width: 70%;
    background: white;
    padding: 15px;
}

.rightside {
    margin: 2% 1%;
    width: 30%;
    padding: 15px 10px;

    display: flex;
    align-items: center;
    flex-direction: column;

    background: white;
}

.finishBottomArea {
    width: 100%;
    margin-top: auto;
}

.statsArea {
    width: 90%;
    margin-bottom: 5px;
}

@media (max-width: 1050px) {
    .leftside {
        width: 93%;
    }

    .rightside {
        width: 93%;
    }
}

@media (max-width: 500px) {
    .leftside {
        width: 87%;
    }

    .rightside {
        width: 87%;
    }
}