.leftside {
    margin: 2% 1%;
    width: 30%;
    background: white;
}

.rightside {
    margin: 2% 1%;
    width: 70%;
    padding: 15px 10px;

    display: flex;
    align-items: center;
    flex-direction: column;

    background: white;
}

.framesChoice {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid lightgray;
    width: 100%;
}

.buttonsArea {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-self: end;
    margin-top: auto;
    margin-bottom: 10px;
}

@media (max-width: 1050px) {
    .leftside {
        width: 98%;
    }

    .rightside {
        width: 95%;
        height: 100%;
    }
}

@media (max-width: 600px) {
    .leftside {
        width: 98%;
    }

    .rightside {
        width: 92%;
    }
}