.headerText {
    font-size: 13px;
    font-weight: bold;
}

.explanationText {
    white-space: pre-line;
    font-size: 12px;

    overflow-y: auto;
    max-height: 18vh;
}

.explanationText::-webkit-scrollbar-track {
    border: 1px solid #ffffff;
    padding: 2px 0;
    background-color: #ffffff;
}

.explanationText::-webkit-scrollbar {
    width: 2px;
}

.explanationText::-webkit-scrollbar:hover {
    width: 5px;
}

.explanationText::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #737272;
    border: 1px solid lightgray;
}

@media (max-width: 1050px) {
    .explanationText {
        max-height: max-content;
    }
}