.frameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-top: 20px;
}

.questionsPicker {
    margin-bottom: 20px;
}