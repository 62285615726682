.dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    opacity: 0.7;
    margin: auto;
}

.easy {
    background-color: var(--success-primary);
}

.medium {
    background-color: var(--color-yellow);
}

.hard {
    background-color: var(--color-red);
}