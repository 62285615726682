.formArea {
    padding: 5px;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.dependentArea {
    background: var(--nav-primary);
    width: 33%;
    margin: 1%;
    flex-direction: column;
    padding: 30px;
}

.rightArea {
    background: var(--nav-primary);
    padding: 30px;
    width: 35%;
    margin-left: 10px;
    flex-direction: column;
}

.selectElementsArea {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.photoArea {
    display: flex;
    justify-content: center;
    min-width: 400px;
    min-height: 400px;
    background-color: var(--container-primary);
    margin-bottom: 20px;
}

.image {
    max-width: 400px;

}

.datePicker {
    margin-top: 50px;
}

@media (max-width: 900px) {
    .formArea {
        flex-direction: column;
        width: 100%;
    }

    .leftArea {
        width: 90%;
        margin-bottom: 20px;
    }

    .rightArea {
        width: 90%;
        margin-bottom: 20px;
    }

    .dependentArea {
        width: 88%;
    }
}

@media (max-width: 685px) {
    .selectElementsArea {
        flex-direction: column;
    }

    .selectElement {
        width: 100%;
        margin-bottom: 10px;
    }

    .dependentArea {
        width: 80%;
    }
}