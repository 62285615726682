:root {
  --color-red: #d35f5f;
  --color-red-hover: #984444;
  --color-red-transparent: #d35f5f60;
  --color-red-transparent-hover: #f6ebeb;
  --color-yellow: #e0dea3;
  --color-yellow-hover: #8c895f;
  --color-yellow-transparent: #e0dea340;
  --background-color: #dce3ed;
  --background-color-hover: #becbde;
  --resources-primary: #314e65;
  --container-primary: #f9fbfe;
  --table-container-primary: #f3f4fc;
  --nav-primary: #ffffff;
  --modal-primary: #e5e5e5;
  --inputs-primary: #f8fcff;
  --success-primary: #79cd7d;
  --success-hover: #69aa6c;
  --success-transparent: #79cd7d60;
  --success-primary-transparent: #79cd7d40;
  --color-orange: #e0bba3;
  --color-orange-hover: #998471;
  --color-gray: #F3F3F3;
  --color-gray-hover: #a7a7a7;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background:
          linear-gradient(217deg, rgba(224,222,163,1), rgba(211,95,95,1) 70.71%),
          linear-gradient(127deg, rgba(211,95,95,1), rgba(224,222,163,1) 70.71%),
          linear-gradient(336deg, rgba(224,222,163,1), rgba(224,222,163,1) 70.71%),
          linear-gradient(336deg, rgba(211,95,95,1), rgba(211,95,95,1) 70.71%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}