.mainArea {
    height: 95%;
    display: flex;
    flex-direction: row;
    max-width: 350px;
}

.presentingArea {
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    max-height: 60vh;
}

.questionImage {
    max-width: 350px;
    margin: 2%;
}

.questionText {
    font-size: 14px;
    white-space: pre-line;
    margin-left: 2%;
}

.presentingArea::-webkit-scrollbar-track {
    border: 1px solid #ffffff;
    padding: 2px 0;
    background-color: #ffffff;
}

.presentingArea::-webkit-scrollbar {
    width: 2px;
}

.presentingArea::-webkit-scrollbar:hover {
    width: 5px;
}

.presentingArea::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #737272;
    border: 1px solid lightgray;
}

@media (max-width: 1050px) {
    .mainArea {
        max-width: max-content;
        flex-direction: column;
        height: auto;
    }

    .presentingArea {
        max-height: max-content;
    }

    .questionImage {
        align-self: center;
    }
}