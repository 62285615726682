i {
    color: var(--color-yellow);
}

.treeView {
    padding: 15px 15px 5px 5px;
    overflow-y: auto;
    max-height: 69vh;
}

.treeView::-webkit-scrollbar-track {
    border: 1px solid #ffffff;
    padding: 2px 0;
    background-color: #ffffff;
}

.treeView::-webkit-scrollbar {
    width: 2px;
}

.treeView::-webkit-scrollbar:hover {
    width: 5px;
}

.treeView::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #737272;
    border: 1px solid lightgray;
}

.react-checkbox-tree {
    font-size: 18px;
}