.questionsPicker {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
}

.levelPickingArea {
    width: 90%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
}

.levelPickers {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.levelAlertLabel {
    margin-top: 25px;
    align-self: center;
    max-width: 90%;
}

.showResultArea {
    margin-top: 10px;
}

@media (max-width: 1050px) {
    .levelPickers {
        flex-direction: column;
        align-items: center;
    }
}