.questionHeaderLabel {
    color: var(--color-red);
}

.separationLine {
    border: 1px solid #E5E5E5;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 15px;
}

.mainElements {
    display: flex;
    flex-direction: column;
}

.formArea {
    display: flex;
    flex-direction: column;
}

.paper {
    background-color: white;
    border-radius: 5px;
    padding: 3px 4px;
    /*padding: theme.spacing(2, 4, 3),*/
    min-width: 550px;
}