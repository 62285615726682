.progressContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.questionLabel {
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
}