.questionsList {
    padding: 15px 15px 5px 5px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 55vh;
    margin-bottom: 3px;
    width: 95%;
}

.questionsList::-webkit-scrollbar-track {
    border: 1px solid #ffffff;
    padding: 2px 0;
    background-color: #ffffff;
}

.questionsList::-webkit-scrollbar {
    width: 2px;
}

.questionsList::-webkit-scrollbar:hover {
    width: 5px;
}

.questionsList::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #737272;
    border: 1px solid lightgray;
}