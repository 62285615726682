.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.resultsBlock {
    display: flex;
    flex-direction: column;
}

.resultsLink {
    color: black;
    text-decoration: none;
}

.resultsLink:hover {
    text-decoration: underline;
}