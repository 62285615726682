.horizontalPresenting {
    display: flex;
    flex-direction: row;
    height: 90%;
}

.verticalPresenting {
    display: flex;
    height: 90%;
    flex-direction: column;

    overflow-y: auto;
    max-height: 62vh;
    overflow-x: hidden;
}

.answersArea {
    width: 100%;
    height: 95%;
    margin-left: 2%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
}

.explanationArea {
    margin-right: 4%;
    margin-top: auto;
}

.verticalPresenting::-webkit-scrollbar-track {
    border: 1px solid #ffffff;
    padding: 2px 0;
    background-color: #ffffff;
}

.verticalPresenting::-webkit-scrollbar {
    width: 2px;
}

.verticalPresenting::-webkit-scrollbar:hover {
    width: 5px;
}

.verticalPresenting::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #737272;
    border: 1px solid lightgray;
}

@media (max-width: 1050px) {
    .verticalPresenting {
        height: auto;

        max-height: max-content;
        overflow-x: hidden;
    }
}