.appBar {
    flex-grow: 1;
    background-color: white !important;
    height: 13vh;
}

.centeredLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
}

.tabsBox {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


.logoTitle {
    font-size: 22px;
    color: black;
    max-width: 180px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.1em;
}

.logoImg {
    align-self: center;
    max-height: 45px;
    max-width: 45px;
}

.logoLabel {
    margin: 0;
}

.username {
    margin-right: 20px;
    color: black;
}

.profileManage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}