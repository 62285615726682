.header {
  display: flex;
  justify-content: space-between;
  height: 80px;
  background-color: var(--nav-primary);
  padding: 5px 20px;
}
.header > h2 {
  font-size: 20px;
  line-height: 80px;
  font-weight: 500;
}
