.appBar {
    flex-grow: 1;
    background-color: white !important;
    width: 100%;
    padding: 0;
    margin: 0;
}

.centeredLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
}

.tabsBox {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.logoTitle {
    font-size: 22px;
    color: black;
    max-width: 180px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.1em;
}

.logoImg {
    align-self: center;
    max-height: 45px;
    max-width: 45px;
}

.logoLabel {
    margin: 0;
    color: black;
    font-size: 14px;
}