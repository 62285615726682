.splitedString {
    text-overflow: ellipsis;
    max-width: 23vw;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px !important;
    margin: 2px;
}

@media (max-width: 1050px) {
    .splitedString {
        max-width: 100%;
    }
}