.rowContainer {
    height: 100%;
    margin: 2% 4%;
    background-color: var(--container-primary);
    border-radius: 10px;

    display: flex;
    flex-direction: row;
}

.columnContainer {
    height: 100%;
    margin: 2% 4%;
    background-color: var(--container-primary);
    border-radius: 10px;

    display: flex;
    flex-direction: column;
}


.fullSizeContainer {
    height: 87vh;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1050px) {
    .fullSizeContainer {
        height: auto;
    }
}

@media (max-width: 700px) {
    .container {
        width: auto;
    }

    .fullSizeContainer {
        height: auto;
    }
}

@media (max-width: 400px) {
    .rowContainer {
        height: auto;
    }

    .columnContainer {
        height: auto;
    }
}