.header {
    display: flex;
    width: 100%;
    justify-content: right;
    align-items: center;
}

.challengeDate {
    width: 25%;
    font-size: 14px;
}

.challengeType {
    width: 20%;
}

.complexity {
    width: 5%;
}

.questionsCount {
    width: 25%;
}

.challengeResult {
    width: 12.5%;
}

.status {
    width: 5%;
}

.textArea {
    text-overflow: ellipsis;
    max-width: 450px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px !important;
}

@media screen and (max-width: 1270px) {
    .textArea {
        max-width: 200px;
    }
}

@media screen and (max-width: 1070px) {
    .textArea {
        max-width: 100px;
    }
}