.root {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.leftsideArea {
    width: 30%;
    background-color: white;
}

.welcomePlacement {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.welcomeLogo {
    width: 35%;
}

.welcomeLabel {
    color: var(--color-yellow);
    text-align: center;
}

.rightsideRoot {
    background: url(../../static/loginback.png) no-repeat 20% 50%;
    width: 70%;
}

.rightsideArea {
    height: 100%;
    box-sizing: border-box;
    background-color: rgba(190, 190, 190, 0.5);
    padding: 2rem;
    line-height: 1.2;
    white-space: pre-wrap;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loginArea {
    background-color: white;
    height: 70%;
    width: 50%;
    border-radius: 7px;
    box-shadow: #757575 2px 2px 3px;

    padding: 10px;

    display: flex;
    flex-direction: column;
}

.loginLabel {
    text-align: center;
    letter-spacing: 0.5px;

    margin-bottom: 30px;
}

@media (max-width: 1000px) {
    .root {
        flex-direction: column;
    }

    .leftsideArea {
        width: 100%;
    }

    .welcomeLogo {
        width: 7%;
        padding: 5px;
        margin: 0;
    }

    .rightsideRoot {
        width: 100%;
        height: 100%;
    }

    h2 {
        font-size: 20px;
        margin: 0;
        padding: 3px;
    }

    .loginArea {
        width: 50%;
        height: 100%;
    }

    .loginLabel {
        font-size: 14px;
    }

    .welcomePlacement {
        flex-direction: row;
    }
}

@media (max-width: 720px) {
    .loginArea {
        width: 70%;
    }

    h2 {
        font-size: 16px;
        margin: 0;
        padding: 3px;
    }

    .loginLabel {
        font-size: 14px;
    }

    .welcomePlacement {
        flex-direction: row;
    }
}

@media (max-width: 490px) {
    .loginArea {
        width: 90%;
        height: 50%;
    }

    h2 {
        font-size: 16px;
        margin: 0;
        padding: 3px;
    }

    .loginLabel {
        font-size: 14px;
    }

    .rightsideArea {
        padding: 0;
    }

    .welcomePlacement {
        flex-direction: row;
    }
}